import { GET_ALL_MISSIONS, ADD_MISSION } from '../actions/actionTypes';

const initialState = {
     missions: [],
     mission: null,
     loading: true
};

export default (state=initialState, action) => {
      const { payload, type} = action;
      switch (type) {
            case GET_ALL_MISSIONS:
                 return {
                       ...state,
                       missions: payload,
                       loading: false
                 }
            case ADD_MISSION:
           
                  return {
                   ...state,
                   missions: [...state.missions, payload],
                   loading: false
                  }      
            default:
                  return state;
      }
};


import { GET_ALL_MEMBERS, ADD_MEMBER } from '../actions/actionTypes';

const initialState = {
     members: [],
     member: null,
     loading: true
};

export default (state=initialState, action) => {
      const { payload, type} = action;
      switch (type) {
            case GET_ALL_MEMBERS:
                 return {
                       ...state,
                       members: payload,
                       loading: false
                 }
            case ADD_MEMBER:
           
                  return {
                   ...state,
                   members: [...state.members, payload],
                   loading: false
                  }      
            default:
                  return state;
      }
};


import gql from "graphql-tag";

/**
 * Records to select from user
 */
const userPayload = `
 id
 username
 email
 createdAt
`;

/**
 * get auth user
 */
export const GET_AUTH_USER = gql`
 query  {
  getAuthUser{
   ${userPayload}

  }
}
`;

/**
 * get all users
 */

export const GET_ALL_USER = gql`
  query ($userId: String, $skip: Int, $limit: Int) {
    getUsers(userId: $userId, skip: $skip, limit: $limit) {
      users {
        id
        username
      }
      count
    }
  }
`;

/**
 * sign up user
 */
export const SIGN_UP = gql`
  mutation ($input: SignUpInput!) {
    signup(input: $input) {
      token
      errors {
        field
        message
      }
    }
  }
`;
/**
 * sign in user
 */
export const SIGN_IN = gql`
  mutation ($input: SignInInput!) {
    signin(input: $input) {
      token
      errors {
        field
        message
      }
    }
  }
`;

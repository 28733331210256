import { GET_ALL_DONATORS, ADD_DONATOR } from '../actions/actionTypes';

const initialState = {
     donators: [],
     donator: null,
     loading: true
};

export default (state=initialState, action) => {
      const { payload, type} = action;
      switch (type) {
            case GET_ALL_DONATORS:
                 return {
                       ...state,
                       donators: payload,
                       loading: false
                 }
            case ADD_DONATOR:
           
                  return {
                   ...state,
                   donators: [...state.donators, payload],
                   loading: false
                  }      
            default:
                  return state;
      }
};


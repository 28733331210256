
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { USER_REGISTER, USER_AUTH, USER_LOGIN,
       CLEAR_PROFILE, LOGOUT,
       AUTH_ERROR,
       LOGIN_SUCCESS,
       REGISTER_ERROR,
       USER_LOADED,
       LOGIN_ERROR } from './actionTypes';
import setAuthToken from '../utils/setAuthToken';
import { setCookie, getCookie, removeCookie } from '../utils/auth';
const cookieName = process.env.REACT_APP_TOKEN || 'ssbu-t';
// console.log('hre is cookieName', cookieName)
// Load User
// Solved 401 error when user does't exist... by Gelo Udemy
// console.log('here is secession', cookieName)

export const loginAction = (userData) => async dispatch => {
      try {
            dispatch({
                  type: LOGIN_SUCCESS,
                  payload: userData
            });
            setCookie(cookieName, userData&&userData.token);
            dispatch(loadUser());
            
      } catch (error) {
            console.log('here is error', error)
      }
}

/**
 * logout action
 */
export const logoutAction = () => async dispatch => {
      removeCookie(cookieName);
      try {
            dispatch({
                  type: LOGOUT
            })
      } catch (error) {
            console.log(error)
      }
}

/**
 * load User 
 * @param {*} authUser 
 */
export const loadUser = (authUser) => async dispatch => {
      // console.log('here is authUser:', authUser)
      if(getCookie(cookieName)){
            const decodedToken = jwtDecode(getCookie(cookieName));
            if(decodedToken.exp * 1000 < Date.now()) {
      
              removeCookie(cookieName);
            } else {
                  dispatch({
                        type: USER_LOADED,
                        payload: authUser||decodedToken,
                  })
            }
      }
};
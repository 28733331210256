import { combineReducers } from 'redux';
import auth from './auth';
import member from './member';
import donator from './donator';
import mission from './mission';
import gov_groups from './gov_groups';
const rootReducer = combineReducers({
    auth,
    member,
    donator,
    mission,
    gov_groups,
})

export default rootReducer 
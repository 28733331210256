import { GET_ALL_GOV_GROUPS } from '../actions/actionTypes';

const initialState = {
     gov_groups: [],
     gov_group: null,
     loading: true
};

export default (state=initialState, action) => {
      const { payload, type} = action;
      switch (type) {
            case GET_ALL_GOV_GROUPS:
                 return {
                       ...state,
                       gov_groups: payload,
                       loading: false
                 }
            default:
                  return state;
      }
};


import cookie from 'js-cookie';


/**
 * set token to the cookie
 */
export const setCookie = (key, value) => {
      if(process.browser) {
            cookie.set(key, value, { expires: 7 });
      }
};
/**
 * get token from cookie
 */
export const getCookie = key => {
      if(process.browser) {
            return cookie.get(key);
      }
}
/**
 * remove token from cookie
 */
export const removeCookie = key => {
      if(process.browser) {
            cookie.remove(key, { expires: 7 })
      }
}


import React from 'react';

import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading, user} , ...rest }) => {
 
      return(
            <Route {...rest} render={ props => !isAuthenticated && loading ? (<Redirect to="/login">
      
            </Redirect>): (<Component {...props} />) 
            }/>
      )
}


const mapStateToProps = state => ({
      auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute);

// import React from 'react'
// import { Route, Redirect, } from 'react-router-dom'
// import { isAuthenticated } from '@/utils/session'

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     !!isAuthenticated()
//       ? <Component {...props} />
//       : <Redirect to={{
//         pathname: '/login',
//         state: { from: props.location }
//       }} />
//   )} />
// )

// export default PrivateRoute

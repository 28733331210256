import React from 'react'
import { connect } from 'react-redux';
import { Query } from 'react-apollo';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import './App.less'

import PrivateRoute from './components/PrivateRoute'

import LoadableComponent from './utils/LoadableComponent'
import { loadUser } from './actions/auth';
import { getCookie } from './utils/auth';
import { GET_AUTH_USER } from '../src/graphql/user';
import Loading from './components/Loading';

// import store from './store';
const Index = LoadableComponent(import('./pages/Index'))
const Login = LoadableComponent(import('./pages/Login'))
const Register = LoadableComponent(import('./pages/Register'))

const cookieName= process.env.TOKEN || 'ssbu-t';

 const App = () => {


  return (
    <Query query={GET_AUTH_USER}>
         {({ data, loading, error }) => {
           
           if(loading) return <Loading />;
           if(error) return <p>
             Error auth User ....
             {console.log('here is Error:', error)}
             
             
             </p>;
        
            if(getCookie(cookieName)) {
              const { getAuthUser } = data;
              // console.log('hrerei getAuthUser', data)
              loadUser(getAuthUser);
          }


           return (
            <Router>
            <Switch>
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <PrivateRoute path='/' component={Index} />
            {/* <Route path='/create-post' component={CreatePost} /> */}
            </Switch>
            </Router>
           )
         }}
    </Query>
  )
}


const MapStateToProps = (state) => ({

})
export default connect(MapStateToProps, { loadUser })(App);

// import React from 'react'
// import './style.css'

// //此效果来源于https://codepen.io/MarioDesigns/pen/LLrVLK

// class Loading extends React.Component {
//     render() {
//         const { className = '', style = {} } = this.props
//         return (
//             <div id="my-loading" className={className} style={style}>
//                 <div className="loader"></div>
//                 <div className="shadow"></div>
//             </div>
//         )
//     }
// }

// export default Loading 

import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.css'

const Loading = (props) => {

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        const { className = '', style = {} } = props
        return (
            <div id="my-loading" className={className} style={style}>
                 
                      <div className="loader">
                            <Spin indicator={antIcon} />
                      </div>
            
            </div>
        )
};

export default Loading;






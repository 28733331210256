import React from "react";
import ReactDOM from "react-dom";

import { setContext } from "@apollo/client/link/context";

import {
  ApolloClient,
  ApolloLink,
  Observable,
  split,
  InMemoryCache,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";

import { createUploadLink } from "apollo-upload-client";

import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import "./index.css";
import App from "./App";
import { Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import en_US from "antd/lib/locale-provider/en_US";
// import moment from 'moment';
// import 'moment/locale/zh-cn';
import history from "./utils/history";
import { Provider } from "react-redux";
import store from "./store";
import { getCookie } from "./utils/auth";

/**
 * creates a Apollo Link, that adds authentication token
 *
 */
const Token_Name = process.env.REACT_APP_TOKEN || "haohaa";

const createAuthLink = () => {
  const request = (operation) => {
    const token = getCookie(Token_Name);
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
  };

  return new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );
};

/**
 * Helper functions that handles error cases
 */
const handleErrors = () => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log("graphQLErrors", graphQLErrors);
    }
    if (networkError) {
      console.log("networkError", networkError);
    }
  });
};

const cache = new InMemoryCache();
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL || "http://localhost:5000/graphql",
});

const errorLink = handleErrors();
const authLink = createAuthLink();

const API_URL = process.env.REACT_APP_API_URL;
const WEB_SOCKET_API_URL = process.env.REACT_APP_WEBSOCKET_API_URL;
const websocketApiUrl = WEB_SOCKET_API_URL
  ? WEB_SOCKET_API_URL
  : API_URL.replace("https://", "ws://").replace("http://", "ws://");

let wsLink = () => {};
// Create WebSocket link
const authToken = getCookie(Token_Name);

if (authToken) {
  wsLink = new WebSocketLink({
    uri: websocketApiUrl,
    options: {
      timeout: 60000,
      reconnect: true,
      connectionParams: {
        authorization: `Bearer ${authToken}`,
      },
    },
  });

  // Temporary fix for early websocket closure resulting in websocket connections not being instantiated
  // https://github.com/apollographql/subscriptions-transport-ws/issues/377
  wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
    wsLink.subscriptionClient.maxConnectTimeGenerator.max;
}

// Split links, so we can send data to each link
// depending on what kind of operation is being sent
const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  uploadLink
);

const tokenKey = process.env.REACT_APP_TOKEN || "mystihub";

const client = new ApolloClient({
  // link: authLink.concat(uploadLink)
  link: ApolloLink.from([errorLink, authLink, terminatingLink]),
  cache: cache,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <Provider store={store}>
        <Router history={history}>
          <ConfigProvider locale={en_US}>
            <App />
          </ConfigProvider>
        </Router>
      </Provider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

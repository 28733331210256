import jwtDecode from 'jwt-decode';
import {
     
     USER_AUTH,
     USER_LOGIN,
     USER_REGISTER,
     LOGOUT,
     REGISTER_ERROR,
     LOGIN_ERROR,
     LOGIN_SUCCESS,
     USER_LOADED,
     AUTH_ERROR
    } from '../actions/actionTypes';

import { getCookie, removeCookie } from '../../src/utils/auth';

let initialState = {
   isAuthenticated: null,
   loading: true,
   user: null
}

let cookieName= process.env.REACT_APP_TOKEN || 'ssbu-t';;

if(getCookie(cookieName)) {
      const decodedToken = jwtDecode(getCookie(cookieName));
      if(decodedToken.exp * 1000 < Date.now()) {
            removeCookie(cookieName);
      } else {
            initialState = {
                  loading: false,
                  isAuthenticated: true,
                  user: decodedToken
            }
      }
}
export default (state= initialState, action) =>  {
   const { type, payload } = action;
   switch(type) {
         case USER_REGISTER:
               return {
                        ...state,
                        ...payload,
                        loading: false
                  }
         case USER_LOADED:
               return {
                  ...state,
                  isAuthenticated: true,
                  loading: false,
                  user: payload
                  }
         case USER_LOGIN:
               return {
                     ...state,
                     ...payload,
                     isAuthenticated: true,
                     loading: false
               }
         case USER_AUTH:
               return {
                     ...state,
                     isAuthenticated: true,
                     loading: false,
                     user: payload
               }
         case AUTH_ERROR:
         case REGISTER_ERROR:
         case LOGIN_ERROR:
         case LOGOUT:
               return {
                  ...state,
                  token: null,
                  isAuthenticated: false,
                  loading: false
               }
           

         default:
         return state;
   }
}